import ModelService from './ModelService';
const modelService = new ModelService();

const model = 'users';

export async function IndexUser(role_id) {
  return modelService.IndexModel(`${model}?role_id=${role_id}`);
}

export async function ShowUser(id, role_id) {
  return modelService.ShowModel(
    `${model}/${id}${role_id && `?role_id=${role_id}`}`
  );
}

export async function PutUser(context, id, formData) {
  return modelService.PutModel(`${model}/${id}?context=${context}`, formData);
}

export async function ResendUserWelcomeEmail(id, formData) {
  return modelService.PutModel(`${model}-resend-welcome-email/${id}`, formData);
}

export async function PostUser(formData) {
  return modelService.PostModel(model, formData);
}

export async function DeleteUser(id) {
  return modelService.DeleteModel(`${model}/${id}`);
}

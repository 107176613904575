import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Form, Offcanvas, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useParams } from 'react-router';
import FormAlert from '../../commons/FormAlert';
import {
  DeleteUser,
  PostUser,
  PutUser,
  ShowUser
} from '../../../../services/UserService';
import { useNavigate } from 'react-router-dom';
import FileUploader from '../../commons/fileUploader';
import PropTypes from 'prop-types';

const ManageBackUsers = ({ context, urlElement, role_id }) => {
  const [trigger] = useState(Date.now());
  const [user, setUser] = useState({});
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [errorForm, setErrorForm] = useState(false);
  const [successForm, setSuccessForm] = useState(false);
  const [formData, setFormData] = useState({
    firstname: '',
    lastname: '',
    phone: '',
    email: '',
    profile_picture_url: '',
    role_id: role_id
  });
  let navigate = useNavigate();

  useEffect(() => {
    const fetchUser = async () => {
      const responseUser = await ShowUser(id, role_id);
      if (responseUser.success === true) {
        let dataUser = responseUser.data;
        setUser(dataUser);
        setFormData({
          firstname: dataUser.firstname,
          lastname: dataUser.lastname,
          phone: dataUser.phone,
          email: dataUser.email,
          profile_picture_url: dataUser.profile_picture_url,
          role_id: role_id
        });
      } else {
        toast('Une erreur est survenue, veuillez réessayer plus tard');
      }
    };
    if (id) {
      fetchUser();
    }
  }, [trigger]);

  const deleteObject = async () => {
    const response = await DeleteUser(id);
    if (response.success === true) {
      toast('Utilisateur archivé');
      navigate(`/administration/${urlElement}`);
    } else {
      toast('Une erreur est survenue, veuillez réessayer plus tard');
    }
  };

  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async e => {
    e.preventDefault();
    setIsLoading(true);
    let response = false;
    if (id) {
      response = await PutUser('admin.updateform', id, formData);
    } else {
      response = await PostUser(formData);
    }
    setSuccessForm(false);
    setErrorForm(response.validator);
    setTimeout(function () {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }, 250);
    setIsLoading(false);
    if (response.success === true) {
      const successMessage = `Utilisateur ${response.data.firstname} ${
        response.data.lastname
      } enregistré.${
        !id ? ' Une invitation automatique lui a été envoyée par mail.' : ''
      }`;
      toast(successMessage);
      setSuccessForm(successMessage);
      if (!id) {
        setIsLoading(true);
        setTimeout(function () {
          window.location.href = `/administration/${urlElement}`;
        }, 4000);
      }
    }
  };

  return (
    <>
      {((id && user.id !== null && user.id !== undefined) || !id) && (
        <>
          <Offcanvas
            show={showDelete}
            onHide={() => {
              setShowDelete(false);
            }}
            placement={'end'}
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title>Voulez-vous continuer ?</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Button
                size="sm"
                variant="falcon-danger"
                className="me-2"
                onClick={() => {
                  deleteObject();
                }}
              >
                Oui, archiver l'utilisateur
              </Button>
              <Button
                size="sm"
                variant="falcon-default"
                className="me-2"
                onClick={() => {
                  setShowDelete(false);
                }}
              >
                Annuler
              </Button>
            </Offcanvas.Body>
          </Offcanvas>
          <Form onSubmit={handleSubmit}>
            <Row className="g-3">
              <Col xs={12}>
                <Card>
                  <Card.Body>
                    <Row className="flex-between-center">
                      <Col md>
                        <h3 className="mb-2 mb-md-0 text-primary admin-title mb-0">
                          {' '}
                          {user.id !== null && user.id !== undefined
                            ? `Modifier les informations du ${context}`
                            : `Ajouter un ${context}`}
                        </h3>
                      </Col>
                      <Col xs="auto">
                        <Button
                          size="sm"
                          variant="falcon-default"
                          className="me-2"
                          onClick={() => {
                            window.location.href = `/administration/${urlElement}`;
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="20"
                            height="20"
                            style={{ marginBottom: '2px' }}
                          >
                            <path fill="none" d="M0 0h24v24H0z" />
                            <path d="M10.828 12l4.95 4.95-1.414 1.414L8 12l6.364-6.364 1.414 1.414z" />
                          </svg>
                          {!id ? 'Annuler' : 'Retour'}
                        </Button>
                        <Button
                          size="sm"
                          variant="primary"
                          className="me-2 admin-primary"
                          type="submit"
                          disabled={isLoading}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="18"
                            height="18"
                            style={{ marginBottom: '2px', marginRight: '4px' }}
                          >
                            <path fill="none" d="M0 0h24v24H0z" />
                            <path
                              d="M7 19v-6h10v6h2V7.828L16.172 5H5v14h2zM4 3h13l4 4v13a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1zm5 12v4h6v-4H9z"
                              fill="rgba(255,255,255,1)"
                            />
                          </svg>
                          Enregistrer
                        </Button>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
                {errorForm && (
                  <div className="mt-3">
                    <FormAlert variant="danger" data={errorForm} />
                  </div>
                )}
                {successForm && (
                  <div className="mt-3">
                    <FormAlert
                      className="mt-3"
                      variant="success"
                      data={successForm}
                    />
                  </div>
                )}
              </Col>
              <Col lg={12}>
                <Card className="mb-3 pb-3">
                  <Card.Body className="bg-light">
                    <Row className="gx-2 gy-3">
                      <Col xs={6}>
                        <Form.Group>
                          <Form.Label>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              width="17"
                              height="17"
                              style={{
                                marginRight: '4px',
                                marginBottom: '2px'
                              }}
                            >
                              <path
                                d="M4.828 21l-.02.02-.021-.02H2.992A.993.993 0 0 1 2 20.007V3.993A1 1 0 0 1 2.992 3h18.016c.548 0 .992.445.992.993v16.014a1 1 0 0 1-.992.993H4.828zM20 15V5H4v14L14 9l6 6zm0 2.828l-6-6L6.828 19H20v-1.172zM8 11a2 2 0 1 1 0-4 2 2 0 0 1 0 4z"
                                fill="rgba(100,99,99,1)"
                              />
                            </svg>
                            Photo de profil :
                          </Form.Label>
                          {id && user.id ? (
                            <FileUploader
                              pathToUpload="users/profilepics"
                              formData={formData}
                              setFormData={setFormData}
                              format="img"
                              databaseField="profile_picture_url"
                              cropperEnabled={true}
                              currentImage={user.profile_picture_url}
                            />
                          ) : (
                            <FileUploader
                              pathToUpload="users/profilepics"
                              formData={formData}
                              setFormData={setFormData}
                              format="img"
                              databaseField="profile_picture_url"
                              cropperEnabled={true}
                            />
                          )}
                        </Form.Group>
                      </Col>
                      <Col md="12" className="pb-2 pt-3">
                        <div className="border-dashed-bottom"></div>
                      </Col>
                      <Col md="6">
                        <Form.Group controlId="firstname">
                          <Form.Label>Prénom :</Form.Label>
                          <Form.Control
                            type="text"
                            name="firstname"
                            placeholder="Prénom du membre"
                            value={formData.firstname || ''}
                            onChange={handleFieldChange}
                          />
                        </Form.Group>
                      </Col>
                      <Col md="6">
                        <Form.Group controlId="lastname">
                          <Form.Label>Nom :</Form.Label>
                          <Form.Control
                            type="text"
                            name="lastname"
                            placeholder="Nom du membre"
                            value={formData.lastname || ''}
                            onChange={handleFieldChange}
                          />
                        </Form.Group>
                      </Col>
                      <Col md="6">
                        <Form.Group controlId="email">
                          <Form.Label>Email :</Form.Label>
                          <Form.Control
                            type="email"
                            name="email"
                            placeholder="Adresse email"
                            value={formData.email || ''}
                            onChange={handleFieldChange}
                          />
                        </Form.Group>
                      </Col>
                      <Col md="6">
                        <Form.Group controlId="phone">
                          <Form.Label>Téléphone :</Form.Label>
                          <Form.Control
                            type="text"
                            name="phone"
                            placeholder="Numéro de téléphone"
                            value={formData.phone || ''}
                            onChange={handleFieldChange}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
              <Col xs={12} style={{ marginBottom: '150px' }}>
                <Card>
                  <Card.Body>
                    <Row className="flex-between-center">
                      <Col xs="12">
                        <Button
                          size="sm"
                          variant="falcon-default"
                          className="me-2"
                          onClick={() => {
                            window.location.href = `/administration/${urlElement}`;
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="20"
                            height="20"
                            style={{ marginBottom: '2px' }}
                          >
                            <path fill="none" d="M0 0h24v24H0z" />
                            <path d="M10.828 12l4.95 4.95-1.414 1.414L8 12l6.364-6.364 1.414 1.414z" />
                          </svg>
                          {!id ? 'Annuler' : 'Retour'}
                        </Button>
                        <Button
                          size="sm"
                          variant="primary"
                          className="me-2 admin-primary"
                          type="submit"
                          disabled={isLoading}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="18"
                            height="18"
                            style={{ marginBottom: '2px', marginRight: '4px' }}
                          >
                            <path fill="none" d="M0 0h24v24H0z" />
                            <path
                              d="M7 19v-6h10v6h2V7.828L16.172 5H5v14h2zM4 3h13l4 4v13a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1zm5 12v4h6v-4H9z"
                              fill="rgba(255,255,255,1)"
                            />
                          </svg>
                          Enregistrer
                        </Button>
                        {id && (
                          <Button
                            size="sm"
                            variant="danger"
                            className="me-0 float-end"
                            type="button"
                            onClick={() => {
                              setShowDelete(true);
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              width="14"
                              height="14"
                              style={{
                                marginBottom: '2px',
                                marginRight: '3px'
                              }}
                            >
                              <path
                                d="M7 4V2h10v2h5v2h-2v15a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V6H2V4h5zM6 6v14h12V6H6zm3 3h2v8H9V9zm4 0h2v8h-2V9z"
                                fill="rgba(255,255,255,1)"
                              />
                            </svg>
                            Archiver
                          </Button>
                        )}
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Form>
        </>
      )}
    </>
  );
};

ManageBackUsers.propTypes = {
  context: PropTypes.string,
  urlElement: PropTypes.string,
  role_id: PropTypes.number
};

export default ManageBackUsers;

import React from 'react';
import ManageBackUsers from '../manage';

const ManageAdmins = () => {
  return (
    <ManageBackUsers context="administrateur" urlElement="admins" role_id={1} />
  );
};

export default ManageAdmins;
